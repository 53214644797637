<template>
  <div class="container-fluid pm-0">
    <div class="row">
      <div class="col-sm-12">
        <gradient-box>
          <span>أهلاً</span> <span>{{ $store.getters.firstName }}</span>
          <template #paragraph>
            الاردنية الاولى للمزادات
          </template>
        </gradient-box>
      </div>
    </div>
    <div class="row" v-if="countersLoaded">
      <div class="col-sm-4">
        <RoutedCard
          bg="bg-orange"
          :counter="auctions"
          description="المزادات"
          icon="fa fa-gavel"
          :route="{name: superAdmin ? 'admin.auctions.all' : 'admin.auctions.current'}"
        />
      </div>
      <div class="col-sm-4">
        <RoutedCard
          bg="bg-red"
          :counter="users"
          description="المستخدمين"
          icon="fa fa-users"
          :route="{name: 'users.list'}"
        />
      </div>
      <div class="col-sm-4" v-if="superAdmin">
        <RoutedCard
          bg="bg-info"
          :counter="assistants"
          description="المساعدين"
          icon="fas fa-user-tie"
          :route="{name: 'assistants.list'}"
        />
      </div>
      <div class="col-sm-4">
        <RoutedCard
          bg="bg-green"
          title="بياناتي"
          description="الإعدادات"
          icon="fas fa-user-cog"
          :route="{name: 'admin.profile'}"
        />
      </div>
      <div class="col-sm-4" v-if="superAdmin">
        <RoutedCard
          bg="bg-soft-green"
          :counter="companies"
          description="الشركات"
          icon="fa fa-car"
          :route="{name: 'companies'}"
        />
      </div>
      <div class="col-sm-4" v-if="superAdmin">
        <RoutedCard
          bg="bg-soft-danger"
          :counter="notes"
          description="الملاحظات"
          icon="far fa-clipboard"
          :route="{name: 'notes'}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RoutedCard from "../../UI/RoutedCard";
import GradientBox from "../../UI/GradientBox";
import types from "@/common/types";

export default {
  components: {RoutedCard, GradientBox},
  computed: {
    superAdmin() {
      return this.$store.getters.superAdmin
    },
    countersLoaded() {
      return types.isObject(this.$store.getters.counters)
    },
    counters() {
      return this.$store.getters.counters
    },
    auctions() {
      return !!this.counters ? parseInt(this.counters.auctions) : 0
    },
    users() {
      return !!this.counters ? parseInt(this.counters.users) : 0
    },
    assistants() {
      return !!this.counters ? parseInt(this.counters.assistants) : 0
    },
    companies() {
      return !!this.counters ? parseInt(this.counters.companies) : 0
    },
    notes() {
      return !!this.counters ? parseInt(this.counters.notes) : 0
    },
  }
}
</script>